.prices{
  [data-option='1']{
    display: var(--visible-1);
  }

  [data-option='2']{
    display: var(--visible-2);
  }
}

.features{
  padding-top: 30px;
}

.features__intro{
  text-align: center;
}

.features__title{
  font-size: 3rem;
}

.features__subtitle{
  margin-bottom: 30px;
}

.features__text{
  p{
    font-size: 1.3125rem;

    &:not(:last-of-type){
      margin-bottom: 24px;
    }
  }

  @media (min-width: 768px) {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.bento{
  margin-top: 60px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
    "first first second"
    "third fourth second"
    "fifth fifth fifth";
  }
}

.bento__item{
  background: var(--background-bento-item);
  border-style: solid;
  border-color: var(--color-gray);
  padding: 5vw;
  position: relative;

  @media (min-width: 768px) {
    padding: 2vw;
  }
}

.bento__item--first{
  border-top-left-radius: 6px;
  border-width: 1px;
  grid-area: first;

  @media (max-width: 767px) {
    border-top-right-radius: 6px;
    padding-bottom: 0;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.bento__item--second{
  border-width: 0 1px 1px 1px;
  display: flex;
  flex-direction: column;
  grid-area: second;
  justify-content: space-between;
  padding-bottom: 0;

  @media (min-width: 768px) {
    border-top-right-radius: 6px;
    border-width: 1px 1px 1px 0;
    padding-bottom: 0;
  }
}

.bento__item--third{
  border-width: 0 1px 1px 1px;
  grid-area: third;
  position: relative;
}

.bento__item--fourth{
  border-width: 0 1px 0 1px;
  grid-area: fourth;

  @media (min-width: 768px) {
    border-width: 0 1px 1px 0;
  }
}

.bento__item--fifth{
  border-width: 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  grid-area: fifth;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    border-width: 0 1px 1px 1px;
    padding-bottom: 0;
  }
}

.bento__title{
  font-size: 2.625rem;
  margin-bottom: 21px;
}

.bento__content--first{
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
}

.bento__content--third{
  @media (max-width: 767px) {
    padding-left: 40%;
  }

  @media (min-width: 768px) {
    width: 80%;
  }
}

.bento__content--fourth{
  padding-left: 40%;

  @media (min-width: 768px) {
    padding-left: 30%;
  }
}

.bento__media{

  @media (max-width: 767px) {
    svg{
      height: 100%;
      width: 100%;
    }
  }
}

.bento__media--first{
  /*bottom: 0;
  position: absolute;
  right: 5%;*/
  align-items: end;
  aspect-ratio: 367 / 260;
  display: flex;
  margin-bottom: -5px;

  @media (min-width: 768px) {
    width: 40%;
  }
}

.bento__media--second{
  margin-bottom: -9px;
  margin-top: 30px;
  text-align: center;
}

.bento__media--third{
  left: -6px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  z-index: 99;

  @media (min-width: 768px) {
    left: 100%;
    top: 0;
  }
}

.bento__media--fifth{
  aspect-ratio: 218 / 340;

  @media (max-width: 767px) {
    height: 350px;
    margin-top: 15px;
  }

  @media (min-width: 768px) {
    align-items: end;
    display: flex;
    margin-bottom: -4px;
  }
}

.bento__media--sixth{
  aspect-ratio: 1 / 1;

  @media (max-width: 767px) {
    margin-top: -50%;
  }

  @media (min-width: 768px) {
    align-items: end;
    display: flex;
    margin-bottom: -17px;
    margin-left: -30px;
  }
}