.accordion__item{
  border-bottom: 2px solid var(--color-black);
}

.accordion__toggle{
  align-items: center;
  background: none;
  border: none;
  color: var(--color-black);
  display: flex;
  font-family: var(--font-primary);
  font-size: 1rem;
  justify-content: space-between;
  margin: 0;
  outline: none;
  position: relative;
  padding: 12px 18px;
  text-align: left;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 1.3rem;
  }
}

.accordion__toggle--active{
  .accordion__plus{
    transform: rotate(0deg);
  }
}

.accordion__plus{
  transform: rotate(90deg);
  transform-origin: center;
  transition: transform .3s;
}

.accordion__title{
  font-size: 1.3125rem;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1.5625rem;
  }
}

.accordion__data{
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.accordion__data--visible{
  grid-template-rows: 1fr;
}

.accordion__content{
  font-size: 1.3125rem;
  overflow: hidden;

  .page-content{
    padding: 30px 18px;
  }
}