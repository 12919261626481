.intro{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100svh - 90px);
  padding-bottom: 30px;
  padding-top: 10%;
  text-align: center;
}

.intro__title{
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 21px;

  @media (min-width: 768px) {
    font-size: 3.75rem;
  }
}

.intro__jobs{
  border-radius: 6px;
  box-sizing: content-box;
  display: inline-block;
  height: 40px;
  position: relative;
  transition: all 0.7s;
  width: var(--width);

  @media (min-width: 768px) {
    height: 60px;
  }
}

.intro__job{
  border-bottom: 6px solid var(--color-black);
  font-weight: 900;
  font-style: italic;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -100%);
  transition: transform 0.7s, opacity 0.25s 0.25s;
}

.intro__job--current{
  opacity: 1;
  transform: translate(-50%, 0);
}

.intro__job--next{
  transform: translate(-50%, 100%);
}

.intro__subtitle{
  flex-grow: 1;
}

.intro__more{
  align-items: center;
  color: inherit;
  display: inline-flex;
  font-size: 1.3125rem;
  font-style: italic;
  font-weight: 900;
  flex-direction: column;
  text-decoration: none;
}