.accordion__item {
  border-bottom: 2px solid var(--color-black);
}

.accordion__toggle {
  align-items: center;
  background: none;
  border: none;
  color: var(--color-black);
  display: flex;
  font-family: var(--font-primary);
  font-size: 1rem;
  justify-content: space-between;
  margin: 0;
  outline: none;
  position: relative;
  padding: 12px 18px;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .accordion__toggle {
    font-size: 1.3rem;
  }
}

.accordion__toggle--active .accordion__plus {
  transform: rotate(0deg);
}

.accordion__plus {
  transform: rotate(90deg);
  transform-origin: center;
  transition: transform 0.3s;
}

.accordion__title {
  font-size: 1.3125rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .accordion__title {
    font-size: 1.5625rem;
  }
}

.accordion__data {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.accordion__data--visible {
  grid-template-rows: 1fr;
}

.accordion__content {
  font-size: 1.3125rem;
  overflow: hidden;
}
.accordion__content .page-content {
  padding: 30px 18px;
}

.intro {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100svh - 90px);
  padding-bottom: 30px;
  padding-top: 10%;
  text-align: center;
}

.intro__title {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 21px;
}
@media (min-width: 768px) {
  .intro__title {
    font-size: 3.75rem;
  }
}

.intro__jobs {
  border-radius: 6px;
  box-sizing: content-box;
  display: inline-block;
  height: 40px;
  position: relative;
  transition: all 0.7s;
  width: var(--width);
}
@media (min-width: 768px) {
  .intro__jobs {
    height: 60px;
  }
}

.intro__job {
  border-bottom: 6px solid var(--color-black);
  font-weight: 900;
  font-style: italic;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -100%);
  transition: transform 0.7s, opacity 0.25s 0.25s;
}

.intro__job--current {
  opacity: 1;
  transform: translate(-50%, 0);
}

.intro__job--next {
  transform: translate(-50%, 100%);
}

.intro__subtitle {
  flex-grow: 1;
}

.intro__more {
  align-items: center;
  color: inherit;
  display: inline-flex;
  font-size: 1.3125rem;
  font-style: italic;
  font-weight: 900;
  flex-direction: column;
  text-decoration: none;
}

.prices [data-option="1"] {
  display: var(--visible-1);
}
.prices [data-option="2"] {
  display: var(--visible-2);
}

.features {
  padding-top: 30px;
}

.features__intro {
  text-align: center;
}

.features__title {
  font-size: 3rem;
}

.features__subtitle {
  margin-bottom: 30px;
}

.features__text p {
  font-size: 1.3125rem;
}
.features__text p:not(:last-of-type) {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .features__text {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.bento {
  margin-top: 60px;
}
@media (min-width: 768px) {
  .bento {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: "first first second" "third fourth second" "fifth fifth fifth";
  }
}

.bento__item {
  background: var(--background-bento-item);
  border-style: solid;
  border-color: var(--color-gray);
  padding: 5vw;
  position: relative;
}
@media (min-width: 768px) {
  .bento__item {
    padding: 2vw;
  }
}

.bento__item--first {
  border-top-left-radius: 6px;
  border-width: 1px;
  grid-area: first;
}
@media (max-width: 767px) {
  .bento__item--first {
    border-top-right-radius: 6px;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .bento__item--first {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.bento__item--second {
  border-width: 0 1px 1px 1px;
  display: flex;
  flex-direction: column;
  grid-area: second;
  justify-content: space-between;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .bento__item--second {
    border-top-right-radius: 6px;
    border-width: 1px 1px 1px 0;
    padding-bottom: 0;
  }
}

.bento__item--third {
  border-width: 0 1px 1px 1px;
  grid-area: third;
  position: relative;
}

.bento__item--fourth {
  border-width: 0 1px 0 1px;
  grid-area: fourth;
}
@media (min-width: 768px) {
  .bento__item--fourth {
    border-width: 0 1px 1px 0;
  }
}

.bento__item--fifth {
  border-width: 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  grid-area: fifth;
}
@media (max-width: 767px) {
  .bento__item--fifth {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .bento__item--fifth {
    border-width: 0 1px 1px 1px;
    padding-bottom: 0;
  }
}

.bento__title {
  font-size: 2.625rem;
  margin-bottom: 21px;
}

@media (max-width: 767px) {
  .bento__content--first {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .bento__content--first {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .bento__content--third {
    padding-left: 40%;
  }
}
@media (min-width: 768px) {
  .bento__content--third {
    width: 80%;
  }
}

.bento__content--fourth {
  padding-left: 40%;
}
@media (min-width: 768px) {
  .bento__content--fourth {
    padding-left: 30%;
  }
}

@media (max-width: 767px) {
  .bento__media svg {
    height: 100%;
    width: 100%;
  }
}

.bento__media--first {
  /*bottom: 0;
  position: absolute;
  right: 5%;*/
  align-items: end;
  aspect-ratio: 367/260;
  display: flex;
  margin-bottom: -5px;
}
@media (min-width: 768px) {
  .bento__media--first {
    width: 40%;
  }
}

.bento__media--second {
  margin-bottom: -9px;
  margin-top: 30px;
  text-align: center;
}

.bento__media--third {
  left: -6px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
@media (min-width: 768px) {
  .bento__media--third {
    left: 100%;
    top: 0;
  }
}

.bento__media--fifth {
  aspect-ratio: 218/340;
}
@media (max-width: 767px) {
  .bento__media--fifth {
    height: 350px;
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .bento__media--fifth {
    align-items: end;
    display: flex;
    margin-bottom: -4px;
  }
}

.bento__media--sixth {
  aspect-ratio: 1/1;
}
@media (max-width: 767px) {
  .bento__media--sixth {
    margin-top: -50%;
  }
}
@media (min-width: 768px) {
  .bento__media--sixth {
    align-items: end;
    display: flex;
    margin-bottom: -17px;
    margin-left: -30px;
  }
}

.prices {
  margin-top: 120px;
}

.prices__intro {
  text-align: center;
}
.prices__intro p {
  font-size: 1.3125rem;
}
.prices__intro p:not(:last-of-type) {
  margin-bottom: 24px;
}

.prices__types {
  margin-bottom: 60px;
  margin-top: 60px;
  text-align: center;
}

.switcher {
  align-items: center;
  background-color: var(--color-light-gray);
  border: 1px solid var(--color-gray);
  border-radius: 2px;
  display: inline-flex;
  padding: 2px;
}

.switcher__item {
  background: none;
  border: none;
  border-radius: 2px;
  color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 0.75rem;
  font-weight: 900;
  line-height: 1;
  padding: 9px 15px;
  text-transform: uppercase;
}

.switcher__item--selected {
  background-color: var(--color-black);
  color: var(--color-accent);
}

.price-table {
  border: 1px solid var(--color-gray);
  margin-bottom: 48px;
  table-layout: fixed;
  width: 100%;
}
@media (max-width: 767px) {
  .price-table {
    display: none;
  }
}

.price-table__description-colum {
  background-color: var(--color-light-gray);
  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  width: 40%;
}

.price-table__value-colum {
  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  width: 20%;
}
.price-table__value-colum:nth-of-type(3) {
  background-color: var(--color-accent-light);
}

.price-table__header {
  font-weight: 400;
  padding: 15px;
}
.price-table__header:nth-of-type(3) {
  background-color: var(--color-accent);
}

.price-table__plan-name {
  display: block;
  font-size: 1.75rem;
  font-weight: 800;
  margin-bottom: 21px;
  text-transform: uppercase;
}

.price-table__row {
  border: 1px solid var(--color-gray);
}

.price-table__data {
  padding: 15px;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.price-table__data--common {
  font-size: 1.3125rem;
  text-align: left;
}

.price-table__data--description {
  text-align: left;
}

.price-table__feature-title {
  display: block;
  font-size: 1rem;
  font-weight: 900;
}
@media (min-width: 768px) {
  .price-table__feature-title {
    font-size: 1.3125rem;
  }
}

.info-button {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.price-table__price {
  display: block;
  font-size: 1.8rem;
}

.price-table__time {
  font-size: 0.8rem;
}

.price-table__action {
  margin-top: 42px;
}

.prices__extra-title {
  font-size: 1.75rem;
  margin-bottom: 21px;
}

.prices__extra-title--highlight {
  text-transform: uppercase;
}

.banner-custom {
  border: 1px solid var(--color-gray);
  padding: 27px;
}
@media (min-width: 768px) {
  .banner-custom {
    margin-left: 40%;
  }
}

@media (max-width: 767px) {
  .banner-custom__content p {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .banner-custom__content {
    align-items: center;
    display: flex;
    gap: 20%;
  }
}

.price-card {
  border: 1px solid var(--color-gray);
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .price-card {
    display: none;
  }
}

.price-card--highlight {
  background-color: var(--color-accent-light);
}
.price-card--highlight .price-card__header {
  background-color: var(--color-accent);
}

.price-card__header {
  border-bottom: 1px solid var(--color-gray);
  padding: 18px 21px;
  text-align: center;
}

.price-card__row {
  border-bottom: 1px solid var(--color-gray);
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: 70% 1fr;
  padding: 18px 21px;
}
.price-card__row:last-of-type {
  border-bottom: none;
}

.price-card__row--full {
  grid-template-columns: 1fr;
}

.price-card__data {
  align-self: center;
  justify-self: center;
  text-align: center;
}

.price-card__footer {
  padding: 18px 21px;
  text-align: center;
}

.tooltip {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: absolute;
  left: 0;
  max-width: 200px;
  opacity: 0;
  top: 0;
  transition: opacity 0.3s;
}

.tooltip--visible {
  opacity: 1;
}

.tooltip__arrow {
  background-color: var(--color-white);
  border-color: var(--color-gray);
  border-style: solid;
  border-width: 0 0 1px 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 8px;
  position: absolute;
  transform: rotate(-135deg);
  width: 8px;
}

.faq {
  margin-top: 120px;
}
@media (min-width: 768px) {
  .faq {
    display: grid;
    grid-template-columns: 40% 1fr;
  }
}