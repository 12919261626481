.prices{
  margin-top: 120px;
}

.prices__intro{
  text-align: center;

  p{
    font-size: 1.3125rem;

    &:not(:last-of-type){
      margin-bottom: 24px;
    }
  }
}

.prices__types{
  margin-bottom: 60px;
  margin-top: 60px;
  text-align: center;
}

.switcher{
  align-items: center;
  background-color: var(--color-light-gray);
  border: 1px solid var(--color-gray);
  border-radius: 2px;
  display: inline-flex;
  padding: 2px;
}

.switcher__item{
  background: none;
  border: none;
  border-radius: 2px;
  color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 0.75rem;
  font-weight: 900;
  line-height: 1;
  padding: 9px 15px;
  text-transform: uppercase;
}

.switcher__item--selected{
  background-color: var(--color-black);
  color: var(--color-accent);
}

.price-table{
  border: 1px solid var(--color-gray);
  margin-bottom: 48px;
  table-layout: fixed;
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }
}

.price-table__description-colum{
  background-color: var(--color-light-gray);
  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  width: 40%;
}

.price-table__value-colum{
  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  width: 20%;

  &:nth-of-type(3){
    background-color: var(--color-accent-light);
  }
}

.price-table__header{
  font-weight: 400;
  padding: 15px;

  &:nth-of-type(3){
    background-color: var(--color-accent);
  }
}

.price-table__plan-name{
  display: block;
  font-size: 1.75rem;
  font-weight: 800;
  margin-bottom: 21px;
  text-transform: uppercase;
}

.price-table__row{
  border: 1px solid var(--color-gray);
}

.price-table__data{
  padding: 15px;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.price-table__data--common{
  font-size: 1.3125rem;
  text-align: left;
}

.price-table__data--description{
  text-align: left;
}

.price-table__feature-title{
  display: block;
  font-size: 1rem;
  font-weight: 900;

  @media (min-width: 768px) {
    font-size: 1.3125rem;
  }
}

.info-button{
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.price-table__price{
  display: block;
  font-size: 1.8rem;
}

.price-table__time{
  font-size: 0.8rem;
}

.price-table__action{
  margin-top: 42px;
}

.prices__extra-title{
  font-size: 1.75rem;
  margin-bottom: 21px;
}

.prices__extra-title--highlight{
  text-transform: uppercase;
}

.banner-custom{
  border: 1px solid var(--color-gray);
  padding: 27px;

  @media (min-width: 768px) {
    margin-left: 40%;
  }
}

.banner-custom__content{
  @media (max-width: 767px) {
    p{
      margin-bottom: 30px;
    }
  }

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    gap: 20%;
  }
}

.price-card{
  border: 1px solid var(--color-gray);
  margin-bottom: 30px;

  @media (min-width: 768px) {
    display: none;
  }
}

.price-card--highlight{
  background-color: var(--color-accent-light);

  .price-card__header{
    background-color: var(--color-accent);
  }
}

.price-card__header{
  border-bottom: 1px solid var(--color-gray);
  padding: 18px 21px;
  text-align: center;
}

.price-card__row{
  border-bottom: 1px solid var(--color-gray);
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: 70% 1fr;
  padding: 18px 21px;

  &:last-of-type{
    border-bottom: none;
  }
}

.price-card__row--full{
  grid-template-columns: 1fr;
}

.price-card__data{
  align-self: center;
  justify-self: center;
  text-align: center;
}

.price-card__footer{
  padding: 18px 21px;
  text-align: center;
}

.tooltip{
  background-color: var(--color-white);
  border: 1px solid var(--color-gray);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  padding: 15px;
  position: absolute;
  left: 0;
  max-width: 200px;
  opacity: 0;
  top: 0;
  transition: opacity .3s;
}

.tooltip--visible{
  opacity: 1;
}

.tooltip__arrow{
  background-color: var(--color-white);
  border-color: var(--color-gray);
  border-style: solid;
  border-width: 0 0 1px 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  height: 8px;
  position: absolute;
  transform: rotate(-135deg);
  width: 8px;
}